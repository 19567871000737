import RegistrationFormRequest from '~/pages/auth/registration/components/registration-form.request.ts'
import api from '~/services/api'

const RegistrationService = {
  confirmAccount: async (id: number, token: string, password?: string) => {
    await api.post(`/auth/verify/${id}`, {
      password,
      token,
    })
  },
  register: async (data: RegistrationFormRequest) => {
    await api.post(`/auth/register`, data)
  },
}

export default RegistrationService
